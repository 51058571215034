<template>
  <div>
    <vs-row class="p-0">
        <vs-col vs-type="flex" vs-justify="flex-start" vs-align="center" vs-w="12"> 
            <vx-card class="mr-4 p-0">
                
                    <div class="vx-row mt-1">
                        <div class="vx-col w-3/4" v-if="listaClientes && listaClientes.length > 0">                        
                            <label class="vs-input--label">Cliente</label>
                            <v-select                              
                                label="nome"                                                                 
                                :options="listaClientes"                                  
                                :filter="pesquisarCliente"
                                v-model="filtro.cliente"                                                             
                                class="vs-input--label  w-full">
                                <template v-slot:option="option">                        
                                    <span style='font-family: Verdana, sans-serif;'> {{ option.CNPJCPF }} </span> - {{ option.nome }}  - {{ option.razaoSocial }}
                                </template>
                            </v-select>                              

                        </div>

                        <div class="vx-col w-1/4">
                            <label class="vs-input--label">Placa</label>
                            <vs-input v-model="filtro.placaVeiculo" v-mask="['AAA-#X##', 'AAA-#X##']" icon="calendar_view_day" class="ml-2" /> 
                        </div>
                        
                    </div>
                    <div class="vx-row mt-1">
                        <div class="vx-col w-1/4 ">                            
                            <label class="vs-input--label mr-2">Código Módulo</label>
                            <vs-input v-model="filtro.codigoModulo" class="w-full"/>                             
                        </div>                        
                        <div class="vx-col w-1/4">
                            <label class="vs-input--label mr-2">Data</label>
                            <flat-pickr v-model="filtro.dataGPSDDMMYYYY" :config="configDatePicker"/>
                        </div>
                        <div class="vx-col w-1/4 flex mt-base">
                            <vx-tooltip text="Atualizar">                
                                <vs-button size="small" color="primary" type="filled" :disabled="!habilitarPesquisa" icon-pack="feather" icon="icon-search" @click="fetchLista(filtro)"></vs-button>
                            </vx-tooltip>
                        </div>           
                    </div>                    
            </vx-card>
        </vs-col>
    </vs-row> 
    <vs-row v-if="exibirMensagemNenhumDadoEncontrado">
        <vs-col vs-type="flex" vs-justify="flex-start" vs-align="center" vs-w="12">           
            <vs-alert class="mt-4 mr-4" color="danger" title="Atenção">
                Nenhum rastreamento encontrado.
            </vs-alert>                          
        </vs-col>
    </vs-row>
    <div class="flex items-center mt-2">                    
        <p class="text-sm" v-if="atualizadoAs">Pesquisado em <span class="font-medium">{{atualizadoAs}} </span> | </p>
        <h6 class="ml-2" v-if="listaTelemetrias.length > 0 "> {{listaTelemetrias.length}} registros</h6>     
        <vx-tooltip text="Exportar para CSV">                
            <vs-button size="small" v-if="listaTelemetrias.length > 0 " class="ml-4" color="rgb(11, 189, 135)" type="filled" icon-pack="feather" icon="icon-save" @click="exportarParaCSV"></vs-button>
        </vx-tooltip>               
    </div>
    

     <ag-grid-vue
      v-if="!exibirMensagemNenhumDadoEncontrado"
      ref="agGridTable"           
      :gridOptions="configuracaoGrid.gridOptions"
      class="ag-grid-table ag-grid-altura mt-1"
      :columnDefs="configuracaoGrid.columnDefs"
      :defaultColDef="configuracaoGrid.defaultColDef"
      :rowData="listaTelemetrias"
      :rowHeight="20"
      :headerHeight="25"        
      rowSelection="single"
      colResizeDefault="shift"      
      :animateRows="true"
      :floatingFilter="false"
      :pagination="false"
      :paginationAutoPageSize="false"
      :suppressPaginationPanel="true"
      @grid-ready="onGridReady" 
      >
    </ag-grid-vue>      
  </div>
</template>

<script>
import axios from "@/axios.js"
import flatPickr from 'vue-flatpickr-component';
import 'flatpickr/dist/flatpickr.css';
import {Portuguese as PortugueseLocale} from 'flatpickr/dist/l10n/pt.js';
import generico from "@/generico.js"
import { AgGridVue } from "ag-grid-vue"
import '@/assets/concept/scss/tabelaBasica.scss'

export default {
    components: {
        flatPickr,
        AgGridVue
    },
    created() {
        
    },
    computed: {
        habilitarPesquisa() {
            if (this.filtro.dataGPSDDMMYYYY) {
                if (this.filtro.codigoModulo) {
                    return true;
                } else if (this.filtro.placaVeiculo && this.filtro.cliente) {
                    return true;
                }
            }

            return false;
        }
    },
    data() {
        return {
            listaClientes: [],
            filtro : { dataGPSDDMMYYYY: null, codigoModulo: null, placaVeiculo: null, cliente: null},
            atualizadoAs: null,
            exibirMensagemNenhumDadoEncontrado: false,            
            listaTelemetrias: [],            
            configDatePicker: {          
                altInput: true,
                altFormat: "d-m-Y",
                dateFormat: "d/m/Y", 
                altInputClass: "vs-inputx vs-input--input normal hasValue text-center",
                locale: PortugueseLocale
            },     
            configuracaoGrid: { 
                gridApi: null, gridOptions: {
                    suppressColumnVirtualisation: true, // Add this line
                }, 
                defaultColDef: { sortable: true, resizable: true, suppressMenu: true,  minWidth: 25  },          
                columnDefs: [    
                    {headerName: "N.", valueGetter: "node.rowIndex + 1", width: 40},    
                    
                    { headerName: 'Módulo', field: 'codigoModulo', width: 130 },           
                    { headerName: 'Data/Hora GPS', field: 'dataHoraGPS', width: 130 },           
                    { headerName: 'Ignição', field: 'ignicao', width: 100},                                              
                    { headerName: 'Odometro m', field: 'odometro', width: 100},                                              
                    { headerName: 'Horimetro min', field: 'horimetro', width: 100},                                              
                    { headerName: 'Nivel Combustivel', field: 'nivelCombustivel', width: 100 },  
                    { headerName: 'Nivel Combustivel %', field: 'nivelCombustivelPercentual', width: 100 },  
                    { headerName: 'Temperatura Motor', field: 'temperaturaMotor', width: 100 },                                
                    { headerName: 'Peso Eixo', field: 'pesoEixo', width: 50},                                             
                    { headerName: 'Velocidade', field: 'velocidade', width: 50},
                    { headerName: 'Velocidade GPS', field: 'velocidadeGPS', width: 50},                    
                    { headerName: 'RPM', field: 'rpm', width: 60},                                              
                    { headerName: 'Pressao Pedal Acelerador', field: 'pressaoPedalAcelerador', width: 100},
                    { headerName: 'Cinto Seguranca', field: 'cintoSegurancaLigado', width: 100},                                              
                    { headerName: 'Ar Condicionado', field: 'arCondicionadoLigado', width: 100},                                              
                    { headerName: 'Porta Motorista', field: 'portaMotoristaAberta', width: 100},                                              
                    { headerName: 'Porta Passageiro', field: 'portaPassageiroAberta', width: 100},                                              
                    { headerName: 'Porta Traseira Esquerda', field: 'portaTraseiraEsquerdaAberta', width: 100},                                              
                    { headerName: 'Porta Traseira Direita', field: 'portaTraseiraDireitaAberta', width: 100},                                              
                    { headerName: 'Farol', field: 'farolLigado', width: 100},                                              
                    { headerName: 'Total Combustivel Utilizado', field: 'totalCombustivelUtilizado', width: 100},                
                    { headerName: 'Total Combustivel Utilizado Parado', field: 'totalCombustivelUtilizadoParado', width: 100},                
                    { headerName: 'Combustivel Utilizado', field: 'combustivelUtilizado', width: 100},                
                    { headerName: 'Tempo Em Movimento Total', field: 'tempoEmMovimentoTotal', width: 100},                
                    { headerName: 'Tempo Parado Total', field: 'tempoParadoTotal', width: 100},                
                    
                    
                ]
            }, 
            linhaSelecionada: null,      
            publicPath: process.env.BASE_URL
        }
    },
    methods: {  
        saveColumnState() {
            var columnState = this.configuracaoGrid.gridOptions.columnApi.getColumnState();
            localStorage.setItem('columnStateListarTelemetria', JSON.stringify(columnState));        
        },

        loadColumnState() {
            var savedState = localStorage.getItem('columnStateListarTelemetria');
            if (savedState) {
                this.configuracaoGrid.gridOptions.columnApi.applyColumnState({ state: JSON.parse(savedState), applyOrder: true });                        
            }
        },  
        onGridReady(params) {
            this.configuracaoGrid.gridApi = params.api;
            this.configuracaoGrid.gridColumnApi = params.columnApi;   
            this.loadColumnState();                                    
        }, 
        fetchListaClientes() {     
            this.$vs.loading() 
            const params = new URLSearchParams();
            params.append('method', 'FindAll');
            params.append('orderDirection', 'ASC');
            params.append('outputFormat', 'JSON');
            params.append('naoExibirInativos', 'true');
            
            if (this.$store.state.AppActiveUser.perfil === "MONITOR_PANICO" || this.$store.state.AppActiveUser.perfil === "EMPRESA_MONITOR_PANICO") {
                params.append('monitoravelPorCentral', 'true');
            }
            
            axios.post("/ClientesRest", params, {              
                    credentials: 'include',
                    responseEncoding: 'iso88591',
                    withCredentials: true } )
            .then((response) => {
                
                this.$vs.loading.close();
                if (response.data.hasOwnProperty("codigo") && response.data.codigo === "99" ) {
                    this.$router.push({ path: '/' });
                } else {
                    this.listaClientes = response.data;                            
                }
            })
            .catch((error) => { 
                this.$vs.loading.close();
                /* eslint-disable no-console */ console.log("EFETUAR LOGOFF! - fetchLista - " + error); 
            })    
                                
        }, 
        pesquisarCliente(options, varTermoPesquisa) {
            varTermoPesquisa = varTermoPesquisa.toUpperCase();
            return options.filter(function (c) {
                    return (c.nome.toUpperCase().indexOf(varTermoPesquisa) != -1  || c.razaoSocial.toUpperCase().indexOf(varTermoPesquisa) != -1 || 
                    c.CNPJCPF.toUpperCase().indexOf(varTermoPesquisa) != -1);
            })
        },    
        exportarParaCSV() {
            let codigoModulo = this.filtro.codigoModulo;
            if (this.listaTelemetrias && this.listaTelemetrias.length > 0) {
                codigoModulo = this.listaTelemetrias[0].codigoModulo;
            }
            var params = {columnSeparator: ';', fileName: 'listaTelemetrias - ' + codigoModulo + '.csv'};
            this.configuracaoGrid.gridApi.exportDataAsCsv(params);
        },      
        formatarNumero3Decimais(valor) {
            return generico.formatarNumero3Decimais(valor);
        },
        formatarNumero2Decimais(valor) {
            return generico.formatarNumero(valor);
        },        
        fetchLista(filtro) {     
                        
            this.$vs.loading() 
            const params = new URLSearchParams();
            params.append('outputFormat', 'JSON');
            params.append('dataGPSDDMMYYYY', filtro.dataGPSDDMMYYYY);
            if (filtro.codigoModulo) {
                params.append('codigoModulo', filtro.codigoModulo);
            }
            if (filtro.placaVeiculo && filtro.cliente) {
                params.append('idCliente', filtro.cliente.id);
                params.append('placaVeiculo', filtro.placaVeiculo);
            }
            
            axios.post("/ListarTelemetria", params, {              
                    credentials: 'include',
                    responseEncoding: 'iso88591',
                    withCredentials: true } )
            .then((response) => {
                this.atualizadoAs = generico.formatarTimestampParaDMYHMS(new Date().getTime());
                this.$vs.loading.close();
                if (response.data.hasOwnProperty("codigo") && response.data.codigo === "99" ) {
                    this.$router.push({ path: '/' });
                } else {
                    this.listaTelemetrias = response.data;  
                    if (this.listaTelemetrias != null && this.listaTelemetrias.length > 0) {
                        this.exibirMensagemNenhumDadoEncontrado = false;
                    } else {
                        this.exibirMensagemNenhumDadoEncontrado = true;
                    }
                }
            })
            .catch((error) => { 
                this.$vs.loading.close();
                /* eslint-disable no-console */ console.log("EFETUAR LOGOFF! - fetchListaVeiculos - " + error); 
            })    
                                
        },        
    },
    mounted() {               
       this.configuracaoGrid.gridApi = this.configuracaoGrid.gridOptions.api;
       this.configuracaoGrid.gridApi.sizeColumnsToFit();    
       this.fetchListaClientes();
    },
    beforeDestroy(){
       this.saveColumnState();
    }
}
</script>


<style lang="scss">
  .ag-grid-altura{
     height: calc(100vh - 215px);

  }

  .ag-grid-table {
    .ag-header-cell{
        font-size: 10px !important;     
    }
    .ag-cell {   
        font-size: 10px !important;
    } 
  }
</style>
